<template>
  <v-row class="ves-questionnaire-panel reported_questionnaires">
    <v-col cols="12" class="sub-title ml-2">
      {{ `${$t("reported")}  ${$t("questionnaires")}` }}
    </v-col>
    <v-col cols="12">
      <v-expansion-panels class="pr-1" v-model="openPanel">
        <v-expansion-panel
          v-for="(answerGroup, key) in questionnaire.answers"
          :key="key"
        >
          <v-expansion-panel-header
            class="pa-6"
            :expand-icon="openPanel == key ? 'mdi-minus' : 'mdi-plus'"
          >
            <span class="d-flex-inline pt-2 pb-2 date">
              {{ key }}
            </span>
            <span class="float-right flex-2 score">
              {{ $t("score") }} <strong>{{ answerGroup.score.score }}</strong>
            </span>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <h3 class="d-inline-block">{{ $t("patient_responses") }}</h3>
            <h4 class="float-right d-inline-block edit-response">
              <v-icon>icon-edit</v-icon> {{ $t("edit_responses") }}
            </h4>

            <template v-for="(answers, questionType) in answerGroup.data">
              <!-- text question  -->
              <div
                class="text-question mt-10"
                v-if="questionType == 'number'"
                :key="questionType"
              >
                <p class="mb-3">
                  <span>{{ answers.length }}.</span>
                  {{ answers[0].question.locales[0].question_text }}
                </p>
                <p class="pl-5 mb-0">{{ answers[0].answer_text }}</p>
              </div>

              <!-- radio question  -->
              <!-- <template v-if="questionType == 'radio'">
                <v-simple-table
                  class="mt-10"
                  v-for="(answer, answerIndex) in answers"
                  :key="answerIndex"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th></th>
                        <th
                          class="text-center text-uppercase"
                          v-for="option in answer.question.options"
                          :key="option.id"
                        >
                          {{ option.locales[0].option_text }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span class="font-weight-bold">
                            <span class="mr-2">{{ answerIndex + 2 }}.</span>
                            {{ answer.question.locales[0].question_text }}
                          </span>
                        </td>
                        <td
                          v-for="availaleOption in answer.question.options"
                          :key="availaleOption.id"
                        >
                          <div
                            class="circle rounded-circle"
                            :class="{
                              selected: availaleOption.id == answer.option.id,
                            }"
                          >
                            <v-icon v-if="availaleOption.id == answer.option.id"
                              >icon-tick</v-icon
                            >
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template> -->

              <!-- radio question  -->
              <template v-if="questionType == 'radio'">
                <div
                  class="mcq-container mt-10"
                  v-for="(answer, answerIndex) in answers"
                  :key="answerIndex"
                >
                  <div class="question container-fluid">
                    <v-row class="sub-question">
                      <v-col cols="12" class="font-weight-bold">
                        <span class="mr-2">
                          {{ answerIndex + 2 }}.
                          {{ answer.question.locales[0].question_text }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row class="answer">
                      <v-col
                        cols="12"
                        v-for="availaleOption in answer.question.options"
                        :key="availaleOption.id"
                      >
                        <div
                          class="circle rounded-circle d-inline-block"
                          :class="{
                            selected: answer.option && availaleOption.id == answer.option.id,
                          }"
                        >
                          <v-icon v-if="answer.option && availaleOption.id == answer.option.id"
                            >icon-tick</v-icon
                          >
                        </div>
                        <div class="ml-3 d-inline-block">
                          {{ availaleOption.locales[0].option_text }}
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </template>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "VESQuestionnairePanels",

  props: {
    questionnaire: {
      type: Object,
      default: () => [],
    },
  },

  data() {
    return {
      openPanel: null,
    };
  },
};
</script>


<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/partial/questionnaires/components/ves-questionnaire-panels.scss">
</style>
